/* src/components/FormPage.css */
.form-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f4f4f4;
    margin-top: 100px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
 .form-container h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .form-container form {
    display: flex;
    flex-direction: column;
  }
  
  

  .form-container textarea ,input{
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .form-container textarea {
    resize: vertical;
  }
  
 .form-container button {
    padding: 10px 15px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
 .form-container button:hover {
    background-color: #0056b3;
  }
  
  @media (max-width :479px){
    .form-container{
    margin: 15px;
    padding: 15px;
    margin-top: 50px;
    }

  }