/* src/components/Header.css */

/* Header.css */
body {
  font-family: 'Lato', sans-serif; /* Apply Lato font to the entire body */
  width: 100%
}


header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 10px;
  background-color: #0f1a30;
  font-family: 'Lato', sans-serif;
}

.logo img {
  width: 85px;
  height: auto;
}

nav {
  display: flex;
  align-items: center;
}

nav ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

nav ul li {
  margin: 0 14px;
}

nav ul li a {
  text-decoration: none;
  color: #f5fffc;
  font-weight: bold;
  font-family: 'Lato', sans-serif;
  font-size: 15px;
}

.header-contact {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.section-header{
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.container-header{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.header-contact .phone-number {
  color: #f5fffc;
  margin-left: 8px;
  font-family: 'Lato', sans-serif;
  font-size: 15px;
}

.schedule-button-header {
  margin-left: 10px;
  padding: 10px 20px;
  background-color: #bb0cff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-family: 'Lato', sans-serif;
  font-size: 17px;
}

.schedule-button-header:hover {
  background-color: #820cb1;
}

/* Animation for the phone icon */
.header-contact .fa-phone-alt {
  font-size: 1.5rem;
  transition: transform 0.3s ease;
}

.header-contact:hover .fa-phone-alt {
  transform: scale(1.1);
}

@media (max-width: 478px) {
  .logo img {
    width: 70px;
  }

  .schedule-button-header {
    margin-left: 5px;
  }

  header {
    padding: 16px 10px;}
}



