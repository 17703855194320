/* Services.css */
.svg-container {
    text-align: center; /* Center the SVG */
    margin-top: 0px;   /* Add some space above the SVG */
    height: 400px; /* Set the desired height */
    overflow: hidden; /* Hide overflow */
  }
  
  .svg-image {
    width: 100%;  /* Adjust the width as needed */
    height: 100%;  /* Maintain aspect ratio */
    object-fit: cover; 
    transform: scaleY(-1);
  }

  @media screen and (max-width: 768px) {
    .svg-container {
    height: 100px; 
    }}