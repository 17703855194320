/* src/components/Contact.css */

/* Import Lato font from Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');

body {
  font-family: 'Lato', sans-serif; /* Apply Lato font to the entire body */
  width: 100%
}

#contact {
  padding: 50px;
  background-color: #edf1fa; /* Lighter background for a modern look */
}

form {
  display: flex;
  flex-direction: column;
}


.contact-section input{
  padding: 12px;
  margin-bottom: 20px; /* Increased margin for better spacing */
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 92%;
  font-family: 'Lato', sans-serif; /* Apply Lato font to input fields */
}

textarea {

  margin-bottom: 20px; /* Increased margin for better spacing */
  border: 1px solid #ccc;
  border-radius: 5px;
  font-family: 'Lato', sans-serif; /* Apply Lato font to input fields */
  width: 98%;
  height: 150px;
}

button {
  padding: 12px 24px; /* Increased padding for better touch targets */
  background-color: #bb0cff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-family: 'Lato', sans-serif; /* Apply Lato font to button */
  transition: background-color 0.3s ease, transform 0.3s ease; /* Added transform transition */
}

button:hover {
  background-color: #820cb1;
  transform: translateY(-3px); /* Slight upward movement on hover */}

.inter-container{
  display: flex;
  align-items: center;
}

.contact-section{
  display: flex ;
  align-items: stretch;
}

.image-contact{
  display: none;
}

@media (min-width: 478px){
  .contact-section{
   width: 40%;
   display: flex-start;
  }

  form{
    align-items: center;
  }
  .container-contact{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-left: -50px;
    margin-right: -50px;
  }
  .image-contact{
    display: flex;
    text-align: flex-end;
  }

}