.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
 

.section {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.fade-in {
  opacity: 1;
  transform: translateY(0);
}

.slide-in {
  transform: translateX(0);
  opacity: 1;
}

.section.slide-out {
  transform: translateX(-100px);
}



@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
  .App{
    padding: 0px;
    margin:0px
  }
}

.App-header {
  background-color: #edf1fa;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}




@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
