body {
  font-family: 'Lato', sans-serif;
  background-color: #0f1a30;
  width: 100%
}

.about-section {
  display: flex;
  padding: 50px;
  background-color: #0f1a30; /* Slightly lighter background for modern look */
  gap: 30px; /* Increased gap for better spacing */
  flex-wrap: wrap; /* Flex wrap for better responsiveness */
  opacity: 0;
  animation: fadeIn 1s ease-in-out forwards;
}

.about-left {
  flex: 1 1 45%; /* Flex-grow, flex-shrink, flex-basis for responsive design */
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start; /* Centered content */
  text-align: start; /* Centered text */
  margin-left: 40px;
  opacity: 0;
  animation: slideInLeft 1s ease-in-out forwards;
  animation-delay: 0.5s; /* Delay for staggered effect */
}

.about-right {
  flex: 1 1 45%; /* Flex-grow, flex-shrink, flex-basis for responsive design */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; /* Centered content */
  text-align: center; /* Centered text */
  opacity: 0;
  animation: slideInRight 1s ease-in-out forwards;
  animation-delay: 1s; /* Delay for staggered effect */
}

.about-left h2 {
  font-size: 3rem;
  color: #f5fffc; /* Slightly darker for better readability */
  margin-bottom: 20px;
  font-weight: 700; /* Bold text */
  animation: zoomIn 1s ease-in-out forwards;
  animation-delay: 1.5s; /* Delay for staggered effect */
}

.about-left p {
  font-size: 1.2rem;
  color: #f5fffc; /* Improved readability */
  margin-bottom: 20px;
  line-height: 1.6; /* Better line height for readability */
  animation: fadeIn 1s ease-in-out forwards;
  animation-delay: 2s; /* Delay for staggered effect */
}

.schedule-button-about {
  padding: 12px 24px; /* Increased padding for better touch targets */
  background-color: #bb0cff;
  color: white;
  border: none;
  border-radius: 5px;
  width: auto;
  height: auto;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease; /* Added transform transition */
  font-size: 1rem; /* Ensuring button text size is consistent */
  margin-top: 30px;
  display: flex;
  justify-content: start;
  align-items: start; /* Centered content */
  text-align: start;
  animation: fadeIn 1s ease-in-out forwards;
  animation-delay: 2.5s; /* Delay for staggered effect */
}

.schedule-button:hover {
  background-color: #820cb1;
  transform: translateY(-3px); /* Slight upward movement on hover */
}

.about-right img {
  max-width: 100%;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Added shadow for modern look */
  opacity: 0;
  animation: zoomIn 1s ease-in-out forwards;
  animation-delay: 3s; /* Delay for staggered effect */
}

@keyframes fadeIn {
  from {
      opacity: 0;
  }
  to {
      opacity: 1;
  }
}

@keyframes slideInLeft {
  from {
      opacity: 0;
      transform: translateX(-50px);
  }
  to {
      opacity: 1;
      transform: translateX(0);
  }
}

@keyframes slideInRight {
  from {
      opacity: 0;
      transform: translateX(50px);
  }
  to {
      opacity: 1;
      transform: translateX(0);
  }
}

@keyframes zoomIn {
  from {
      opacity: 0;
      transform: scale(0.9);
  }
  to {
      opacity: 1;
      transform: scale(1);
  }
}


@media (max-width: 478px) {
  .about-section {
    flex-direction: column;
    padding: 30px; /* Reduced padding for smaller screens */

  }
  
  .about-right {
    flex: 1 1 100%;
    margin-left: 0px;
  }
  
  .about-left {
    margin-left: 0px;
  }

  .about-left h2 {
    font-size: 1.8rem;
  }

  .about-left p {
    font-size: 1rem;
  }

  .schedule-button-2 {
    padding: 12px 24px; 
    border-radius: 5px;
    width: auto;
    height: auto;
  }
}