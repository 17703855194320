/* src/components/Pricing.css */

/* Import Lato font from Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');

body {
  font-family: 'Lato', sans-serif; /* Apply Lato font to the entire body */
}




.pricing-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle, rgba(187, 12, 255, 0.2), rgba(0, 132, 255, 0.2));
  z-index: -1;
}





.pricing-section {
  padding: 8px;
  background-color: #0f1a30; /* Lighter background for a modern look */
  text-align: center;
}

.pricing-section h2 {
  font-size: 38px;
  margin-bottom: 30px;
  color: #f5fffc; /* Increased margin for better spacing */
}

.price-1 p{
  color: #1c6dd1;
  font-size: 43px;
  font-weight: 900;
  margin-block-end: 10px;
  margin-block-start: 10px;

}
.price-2 p{
  color: #1c6dd1;
  font-size:23px;
}

.container-price{
  display: flex;
  align-items: baseline;
  justify-content: center;
}


.web-section h1{
  font-size: 36px;
  color: #f5fffc;
}
.pricing-container {
  display: flex;
  justify-content: center;
  max-width: 1000px;
  height: auto;
  margin: 0 auto;
}

.pricing-card ul {
   text-align: start;
}

.pricing-card {
  
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 30px;
  margin: 10px;
  flex: 1;
  transition: transform 0.3s ease;
  background: linear-gradient(135deg, #ffffff 0%, #e3e3e3 100%);
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
 
}

.pricing-card:hover {
  transform: translateY(-5px);
}

.pricing-header {
  margin-bottom: 20px;
}

.pricing-header h3 {
  font-size: 2rem; /* Increased font size for pricing titles */
  margin-bottom: 10px; /* Adjusted margin for better spacing */
  color: #24296c  ;
}

.web-section p {
  font-size: 20 px;
  color: #f5fffc;
  margin-bottom: 20px; /* Increased margin for better spacing */
}

ul {
  list-style: none;
  padding: 0;
}

ul li {
  font-size: 1.1rem;
  margin-bottom: 15px; /* Increased margin for better spacing */
}

.pricing-header img{
  width: 76%;
  height: auto;
}

.start-button {
  padding: 12px 24px; /* Increased padding for better touch targets */
  background-color: #1c6dd1;
  color: #f5fffc;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.1rem;
  transition: background-color 0.3s ease;
}

.start-button:hover {
  background-color: #640988;
}

/* Responsive design for smaller screens */
@media (max-width: 768px) {
  .pricing-container {
    flex-direction: column;
    gap: 30px; /* Increased gap between pricing cards */
  }
  .web-section h1{
    font-size: 23px;
  }
  .pricing-section h2 {
    font-size: 24px;}

    .pricing-header img{
      width: 56%;}
}
