.section {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.6s ease-out, transform 0.6s ease-out;
  }
  
  .section.animate {
    opacity: 1;
    transform: translateY(0);
  }
  .animated-card{
    display: flex;
    align-items: start;
  }