body {
  font-family: 'Lato', sans-serif; /* Apply Lato font to the entire body */
  width: 100%
}

.testimonials {
    padding: 2rem;
    text-align: center;
    background-color: #0f1a30;
    padding: 50px;
  }
  
.testimonials h2{
  font-size: 36px;
  margin-bottom: 20px;
  font-family: 'Lato', sans-serif;
  color :#f5fffc;
}
.testimonials p{
  color: black;
}

.testimonials h3{
  color :#f5fffc;
}
  
  .testimonial-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
  }
  
  .testimonial-card-container {
    max-width: 600px;
    width: 100%;
    position: relative;

  }
  
  .testimonial-card {
  background: linear-gradient(135deg, #ffffff 0%, #e3e3e3 100%);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 1rem;
    transition: transform 0.3s ease;
    position: relative;
    border-radius: 25px;
  }
  .testimonial-card::before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(circle, rgba(187, 12, 255, 0.2), rgba(0, 132, 255, 0.2));
    z-index: -1;
  }
  
  .testimonial-card:hover {
    transform: translateY(-5px);
  }
  
  .testimonial-content {
    margin-bottom: 1.5rem;
  }
  
  .testimonial-author {
    display: flex;
    align-items: center;
  }
  
  .testimonial-author img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 1rem;
  }
  
  .swipe-button {
    background-color: transparent;
    border: none;
    font-size: 2rem;
    cursor: pointer;
    color: #333;
    transition: color 0.3s ease;
    position: absolute;
    top: 40%;
    color: #f5fffc;
    font-size: 1.4rem; /* Adjust the icon size */
  }
  
  .swipe-button.left {
    left: 1rem;
    margin-left: -73px;
  }
 
  .swipe-button.right {
    right: 1rem;
    margin-right: -73px;

  }

  
  .swipe-button:hover {
    color: #f5fffc;
    background-color: #0f1a30
  }
  
  @media (max-width: 478px) {
    .swipe-button {
      display:flex;
    }
    .testimonials h2{
      font-size: 23px;
    }
  
  }  
