/* src/components/Footer.css */

/* Import Lato font from Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');

body {
  font-family: 'Lato', sans-serif; /* Apply Lato font to the entire body */
  width: 100%
}


footer {
  padding: 30px; /* Increased padding for better spacing */
  background-color: #444; /* Slightly lighter background for modern look */
  color: #fff;
  text-align: center;
  font-size: 1rem; /* Adjusted font size for better readability */
}
.footer-container{
  display: flex;
  text-align: start;
  flex-direction: column;
  
}

.footer-container p{

margin-block-start: 0.5em;
margin-block-end: 0.5em;}
