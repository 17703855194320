/* Add classes for animation states */

body {
  font-family: 'Lato', sans-serif; /* Apply Lato font to the entire body */
  width: 100%
}

.animate {
  opacity: 0;
}

.animate.fadeIn {
  animation: fadeIn 1s ease-in-out forwards;
}

.animate.slideInUp {
  animation: slideInUp 1s ease-in-out forwards;
}

.animate.slideInDown {
  animation: slideInDown 1s ease-in-out forwards;
}

.animate.zoomIn {
  animation: zoomIn 1s ease-in-out forwards;
}

/* Keyframes for animations */
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes slideInUp {
    from {
        opacity: 0;
        transform: translateY(50px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes slideInDown {
    from {
        opacity: 0;
        transform: translateY(-50px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes zoomIn {
    from {
        opacity: 0;
        transform: scale(0.9);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}

/* Existing styles */
.services-section-container{
  padding: 0px;
  background-color: #0f1a30;
  text-align: center;
  margin-bottom: 150px;
}

.services-section-container h2 {
  font-size: 36px;
  margin-bottom: 20px;
  font-family: 'Lato', sans-serif;
  color: #f5fffc;
}

.services-section p {
  font-size: 20px;
  margin-bottom: 40px;
  font-family: 'Lato', sans-serif;
 
}

.services-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  font-family: 'Lato', sans-serif;
}

.heading-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}

.service-card {
  background-color: #d8fbef;
  border: 2.5px solid #ddd;
  border-radius: 10px;
  width: 100%;
  max-width: 800px;
  padding: 20px;
  text-align: left;
  transition: all 0.3s ease;
  overflow: hidden;
}

.service-card.expanded {
  height: auto;
}

.card-logo {
  width: 50px;
  height: 50px;
}

.card-title-details {
  flex: 1;
  font-family: 'Lato', sans-serif;
}

.card-title-details h3 {
  margin: 0;
  font-family: 'Lato', sans-serif;
}

.initial-details {
  margin: 5px 0 10px;
  font-size: 1rem;
  color: #232222;
  font-family: 'Lato', sans-serif;
}

.card-header button {
  padding: 5px 10px;
  background-color: #bb0cff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-family: 'Lato', sans-serif;
  transition: background-color 0.3s ease;
}

.card-header button:hover {
  background-color: #820cb1;
}

.card-details {
  margin-top: 20px;
  font-size: 1rem;
  color: #555;
}

.text-below-heading {
  font-size: 1.2rem;
  margin-bottom: 40px;
  font-family: 'Lato', sans-serif;
  color: #f5fffc;
}

.container-service{
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
}
.image-1{
  width: 15%;
  height: auto;
  margin-top: auto;
  margin-bottom: 70px;
}
.image-2{
  width: 15%;
  height: auto;
  margin-bottom: auto;
  margin-top: 70px;

}
.display{
  display: none;
}

@media (max-width: 478px) {
  .services-section-container h2 {
    font-size: 23px;
    display: inline-block;
    justify-content: center;
    align-items: center; /* Centered content */
    text-align: center;
    
  }
  .service-card {
    width: 80%;
  }

  .services-section p {
    font-size: 1rem;
    margin-bottom: 10px;
  }

  .image-1{
    display: none;
  }
  .image-2{
    display: none;
  }
}
