/* src/components/Blog.css */

/* Import Lato font from Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');

body {
  font-family: 'Lato', sans-serif; /* Apply Lato font to the entire body */
  width: 100%
}

.blog-section {
padding: 50px;

  background-color: #ffc995; /* Lighter background for a modern look */
}

.blog-container {
  display: flex;
  align-items: flex-start;
  gap: 40px; /* Increased gap for better spacing */
  width: 100%;
  margin: 0 auto;
}

.blog-image {
  flex: 1;
  display: flex;
  justify-content: center; /* Center the image horizontally */
  align-items: center; /* Center the image vertically */
}

.blog-image img {
  width: 100%;
  max-width: 400px;
  border-radius: 50%; /* Rounded image */
  object-fit: cover;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Added shadow for a modern look */
}

.blog-content {
  flex: 2;
  text-align: left;
}

.blog-content p {
  font-size: 1.2rem; /* Slightly increased font size for better readability */
  line-height: 1.5; /* Improved line height for readability */
  color: #333; /* Darker text color for better contrast */
  font-family: Georgia;
  font-style: oblique;
}

.schedule-button {
  padding: 12px 24px; /* Increased padding for better touch targets */
  background-color: #bb0cff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.1rem; /* Adjusted font size */
  transition: background-color 0.3s ease, transform 0.3s ease; /* Added transform transition */
  text-align: start;
}

.schedule-button:hover {
  background-color: #820cb1;
  transform: translateY(-3px); /* Slight upward movement on hover */
}

@media (max-width: 478px) {
  .blog-container {
    flex-direction: column-reverse;
    gap: 30px; /* Adjusted gap for smaller screens */
  }


  .blog-image {
    order: 2; /* Move the image below the content on smaller screens */
  }

  .blog-content {
    order: 1; /* Move the content above the image on smaller screens */
  }
  .blog-image img {
    width: 80%;
    max-width: 400px;
    border-radius: 50%; /* Rounded image */
    object-fit: cover;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Added shadow for a modern look */
  }
  .blog-section {
    padding-top: 90px;
    padding-bottom: 90px;
    padding-left: 30px;
    padding-right: 30px;

  }

  .blog-content p {
    font-size: 1rem; /* Slightly increased font size for better readability */}
}

  
  