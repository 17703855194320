/* src/Timeline.css */
.container {
    max-width: 600px;
    margin: 0 auto;
 
    display: contents;
  }
  
  body {
    font-family: 'Lato', sans-serif;
    background-color: #0f1a30;
    width: 100%
  }
  
  .relative {
    align-items: flex-start;
    display: flex;
    flex-direction: row;

    gap: 30px;
  }
  
 .block-image-text{
  display: flex;
  flex-direction: row;
  margin-top: 45px;
  justify-content: space-between;
 }
 .block-image-text-1{
  display: flex;
  flex-direction: row;
  margin-top: 0px;
  justify-content: space-between;
  align-items: flex-end;
 }

 .block-image-text-hor{
  display: flex;
  flex-direction: row;
  margin-top: 45px;
  justify-content: space-between;
 }
 .section-timeline{
  margin-left: 150px;
  margin-right: 150px;
  background-color: #0f1a30;
 
 }

 video {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure the video covers the container */
}

 /*.image{
  width: 100%;  /* Adjust the width as needed */
 /* height: 100%;  /* Maintain aspect ratio */
  /*object-fit: cover; */


 .image-container{
  text-align: start; /* Center the SVG */
 }

 .title-button {
  cursor: pointer;
  display: inline-block;
  padding: 0.5em 1em;
  border: 1px solid #1b3450;
  border-radius: 20px;
  background-color: #0d2239;
  color: white;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.title-button:hover {
  background-color: #bb0cff;
}

.title-button:active {
  transform: scale(0.98);
}

.collapsible-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease;
}

.collapsible-content.expanded {
  max-height: 200px; /* Adjust based on the content height */
}


 .image {
  flex: 1 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 260px;
  overflow: hidden;
}

.image img {
  width: 100%;
  max-width: 400px;
  border-radius: 5%; /* Rounded image */
  object-fit: cover;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Added shadow for a modern look */
}
  
  
  .ml-6 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 500px;
  

  }
  
  .mb-1 {
    margin-bottom: 0.25rem;
    margin-top: -0.25rem;
  }
  
  .text-lg {
    font-size: 1.125rem;
  }
  
  .font-semibold {
    font-weight: 600;
  }
  
  .text-zinc-900 {
    color: white;
  }
  
  .dark .text-white {
    color: #ffffff;
  }
  
  .text-sm {
    font-size: 0.875rem;
  }
  
  .text-zinc-400 {
    color: #a1a1a1;
  }
  
  .dark .text-zinc-500 {
    color: #717171;
  }
  
  .text-base {
    font-size: 1.3rem;
    text-align: left;
  }
  
  .text-zinc-500 {
    color: #717171;
  }
  
  .dark .text-zinc-400 {
    color: #a1a1a1;
  }
  
  .inline-flex {
    display: inline-flex;
  }
  
  .items-center {
    align-items: center;
  }
  
  .container-style {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  
  .py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  
  .font-medium {
    font-weight: 500;
  }
  
  .text-white {
    color: #ffffff;
  }
  
  .bg-blue-500 {
    background-color: #3b82f6;
  }
  
  .rounded-lg {
    border-radius: 0.5rem;
  }
  
  .hover\:bg-blue-600:hover {
    background-color: #2563eb;
  }
  
  .focus\:ring-4:focus {
    box-shadow: 0 0 0 4px rgba(59, 130, 246, 0.5);
  }
  
  .focus\:ring-blue-300:focus {
    box-shadow: 0 0 0 4px rgba(147, 197, 253, 0.5);
  }
  
  .dark .bg-blue-600 {
    background-color: #2563eb;
  }
  
  .dark .hover\:bg-blue-700:hover {
    background-color: #1d4ed8;
  }
  
  .focus\:outline-none:focus {
    outline: none;
  }
  
  .dark .focus\:ring-blue-800:focus {
    box-shadow: 0 0 0 4px rgba(30, 58, 138, 0.5);
  }
  
  .absolute {
    position: absolute;
  }
  
  .w-6 {
    width: 1.5rem;
  }
  
  .h-6 {
    height: 1.5rem;
  }
  
  .bg-blue-500 {
    background-color: #3b82f6;
  }
  
  .rounded-full {
    border-radius: 9999px;
  }
  
  .-left-3 {
    left: -1 rem;
    margin-left: -38px;
  }
  
  .ring-8 {
    box-shadow: 0 0 0 8px #ffffff;
  }
  
  .dark .ring-zinc-900 {
    box-shadow: 0 0 0 8px #1c1c1c;
  }
  
  .svg {
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
    fill: currentColor;
  }
  
  .timeline-line {
    display: none;
  }

  .text-below-heading {
    font-size: 1.2rem;
    margin-bottom: 40px;
    font-family: 'Lato', sans-serif;
    color :#f5fffc;
  
}
.services-section h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  font-family: 'Lato', sans-serif;
  color :#f5fffc;
}
@media (min-width: 479px) {
  .mobile-only {
    display: none;
  }
}


  
  @media (max-width: 478px) {
    .container {
      padding: 35px;
      max-width: 600px;
      margin: 0 auto;
      display: contents;
    }

    .-left-3 {
      left: 3rem;
    }

    .desktop-only {
      display: none;
    }
  
    .ml-6 {
      margin-left: 1rem;
      padding-left: 35px;
      padding-right: 10px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: auto;
      
    }
    .timeline-line {
        height: 1134px;
        display: inline;
        left: 1.35rem;
        position: absolute;
        width: 2px;
        background: #ffffff;
        top: 0;
        margin-top: 0px;
        margin-bottom: 0px;
        bottom: 0;
        
      }

      .text-base {
        font-size: 1rem;
        text-align: left;
    }

    .relative{
        display:flex;
        padding: 0px  ;
        position: relative;
       margin: auto;
      
    }

    .block-image-text-hor{
      display: flex;
      flex-direction: column;
      gap: 15px;
      margin-top: -6px;
     }
     .block-image-text{
      display: flex;
      flex-direction: column-reverse;
      gap: 15px;
      align-items: flex-start;
      width: 100%;
      margin: 0 auto;

     }
     .block-image-text-1{
      display: flex;
      flex-direction: column;
      gap: 15px;
      align-items: start;
      width: 100%;
      margin: 0 auto;
     }


    .section-timeline{
      margin-left: 0px;
      margin-right: 0px;
     }
     .container-style{
      padding-left: 0rem;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
     }
   
     .image-container{
      height: 215px;
    width: 286px;
    margin-inline: auto;
    margin-top: 0px;   /* Add some space above the SVG */
    height: 260px;
    width: auto; /* Set the desired height */
    /*overflow: hidden;  Hide overflow */
     }

     .section-timeline h2 {
      font-size: 1.8rem;
      color: #f5fffc;
  
    }

    .text-below-heading {
      font-size: 1.2rem;
      margin-bottom: 80px;;
    
  }
  }
  