.three-reasons {
    text-align: center;
    padding: 20px;
    background-color: #0f1a30 ;
}

.three-reasons h3 {
    color: #007bff; /* Blue color for the subtitle */
    font-size: 23px;
    margin-bottom: 10px;
    animation: fadeInDown 1s ease-in-out;
    color: #f5fffc;

}

.three-reasons h1 {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #f5fffc;
    animation: fadeInDown 1s ease-in-out;
}

.reasons {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.reason {
    max-width: 300px;
    margin: 10px;
    text-align: center;
    opacity: 0;
    animation: fadeIn 1s ease-in-out forwards;
}

.reason img {
    width: 100%;
    height: auto;
    animation: fadeInUp 1s ease-in-out;
}

.reason h3 {
    font-size: 18px;
    margin: 10px 0;
    color: #f5fffc;
    animation: fadeInUp 1.5s ease-in-out;
}

.reason p {
    font-size: 14px;
    color: #555;
    animation: fadeInUp 2s ease-in-out;
    color: #b7c3bf;

}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeInDown {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.image-vag {
    flex: 1;
    display: flex;
    justify-content: center; /* Center the image horizontally */
    align-items: center; /* Center the image vertically */
  }
  
  .image-vag img {
    width: 100%;
    max-width: 400px;
    border-radius: 50%; /* Rounded image */
    object-fit: cover;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Added shadow for a modern look */
  }
 @media (max-width:478px) {
    .three-reasons h1 {
        font-size: 24px;}

        .three-reasons h3 {
            font-size: 18px;}
    
 }