/* Services.css */
.svg-container {
    text-align: center; /* Center the SVG */
    margin-top: 0px;   /* Add some space above the SVG */
    height: 400px; /* Set the desired height */
    overflow: hidden; /* Hide overflow */
  }
  body {
    font-family: 'Lato', sans-serif; /* Apply Lato font to the entire body */
    width: 100%
  }
  
  
  .svg-image-above {
    width: 100%;  /* Adjust the width as needed */
    height: 100%;  /* Maintain aspect ratio */
    object-fit: cover; 
  }
  
  @media screen and (max-width: 768px) {
    .svg-container {
    height: 100px; 
    }}